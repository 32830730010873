$(function(){
  $('a[href^="#"]:not([href="#"])').on("click", function(){
      var anc = $($(this).attr("href")).offset().top;
      $("html,body").animate({scrollTop:anc},500);
      return false;
  });

  var appear = false;
  var pagetop = $('.toplink');
  $(window).on("scroll", function () {
      if ($(this).scrollTop() > 500) {
      if (appear == false) {
          appear = true;
          pagetop.stop().animate({
          'right': '2%' //右から0pxの位置に
          }, 300); //0.3秒かけて現れる
      }
      } else {
      if (appear) {
          appear = false;
          pagetop.stop().animate({
          'right': '-5em' //右から-50pxの位置に
          }, 300); //0.3秒かけて隠れる
      }
      }
  });
  pagetop.on("click", function () {
      $('body, html').animate({ scrollTop: 0 }, 500);
      return false;
  });



//新規登録のセレクトボックス切替
  // $('select[name="job[recruit_type]"]').on("change", function(){
  //   console.log('動いてます');
  //   if($('select[name="job[recruit_type]"]').val() == "紹介求人"){
  //     console.log('紹介求人です');
  //     $("ul.temp").remove();
  //     $("ul").html(
  //     '<ul class="ref">' +
  //       '#{<%=form.collection_check_boxes(:tag_ids,@tags_introduction, :id, :name) do |tag| %>}'+
  //         '<li>'+
  //           '<label>'+
  //             '<%= tag.check_box %>'+
  //             '<%= tag.text %>'+
  //           '</label>'+
  //         '</li>'+
  //       '<% end %>'+
  //     '</ul>'
  //       );
  //   }else{
  //     console.log('派遣求人です');
  //     $("ul").html(
  //       '<ul class="temp">' +
  //         ${form.collection_check_boxes(:tag_ids,@tags_dispatch, :id, :name) do |tag| } +
  //           '<li>'+
  //             '<label>'+
  //               '<%= tag.check_box %>'+
  //               '<%= tag.text %>'+
  //             '</label>'+
  //           '</li>'+
  //         '<% end %>' +
  //       '</ul>'
  //     );
  //     $("ul.ref").remove();
  //   }
  // });




//企業の方へページのコンテンツ部分の切替
  // $('#Corp #About>.box.flow[id != "Temp"]').hide();
  // $("#Corp #About>.tab div").on("click", function(){
  //   $("#Corp #About>.box.flow").hide();
  //   $($(this).attr("data-id")).show();
  //   $(".selected").removeClass("selected");
  //   $(this).addClass("selected");
  // });



//マイページのコンテンツ部分の切替
  $('#MyPage.staff #Open>div[id != "Done"]').hide();
  $("#MyPage.staff #Open>.tab div").on("click", function(){
    $("#MyPage.staff #Open>div").hide();
    $($(this).attr("data-id")).show();
    $(".selected").removeClass("selected");
    $(this).parent().addClass("selected");
  });



// //グローバルナビ、紹介関係へのリンク先切替
//   if($("body").attr("id") == "Referral"){
//     $("#Gnav ul li:nth-child(1) a").attr("href","guest_introduction");
//     $("#Gnav ul li:nth-child(5) a").attr("href","companies/introduction");
//   }
//   if("#Ref" == location.hash) {
//     $("#Temp").hide();
//     $("#Ref").show();
//     $(".selected").removeClass("selected");
//     $('div[data-id="#Ref"]').addClass("selected");
//     $("#Gnav ul li:nth-child(1) a").attr("href","guest_introduction");
//     $("#Gnav ul li:nth-child(5) a").attr("href","companies/introduction");
//   }
//   $("#About>.tab div:nth-child(1)").on("click", function(){
//     $("#Gnav ul li:nth-child(1) a").attr("href","guest_introduction");
//     $("#Gnav ul li:nth-child(5) a").attr("href","companies/introduction");
//   });
//   $("#About>.tab div:nth-child(2)").on("click", function(){
//     $("#Gnav ul li:nth-child(1) a").attr("href","guest_introduction");
//     $("#Gnav ul li:nth-child(5) a").attr("href","companies/introduction");
//   });




  $('.buttons .mark a').on('click', function(){
    $(this).css('background-color', '#EDBDFC!important');
  });


  const setFillHeight = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
  
  // 画面のサイズ変動があった時に高さを再計算する
  window.addEventListener('resize', setFillHeight);
  
  // 初期化
  setFillHeight();




  indexHeight();
$(window).resize(indexHeight);
function indexHeight(){
  let mainHeight = window.innerHeight;
  $("#Index main").css("height", mainHeight);
}


});